<template>
  <div v-if="model">
    <h3 v-if="showHeader">Паспортные данные</h3>
    <base-label label="Паспорт">
      <div class="row">
        <div class="col-4">
          <input :class="{invalid: errors.has('series')}" v-imask="{mask: '00-00', lazy: true}" v-validate="'required'" class="form-control" type="text" v-model="model.series" name="series" />
        </div>
        <div class="col-8 pr-0">
          <div class="row">
            <input :class="{invalid: errors.has('number')}" v-imask="{mask: '000-000', lazy: true}" v-validate="'required'" class="form-control" type="text" v-model="model.number" name="number" />
          </div>
        </div>
      </div>
    </base-label>
    <base-label label="Дата рождения">
      <sweet-date-picker :class="{'invalid': errors.has('date_of_birth')}" name="date_of_birth" v-validate="{required: true}" :range="false" type="date" v-model="model.date_of_birth" />
    </base-label>
    <base-input name="place" v-model="model.place_of_birth" label="Место рождения" />
    <base-input name="code" v-model="model.dep_code" :mask="{mask: '000-000', lazy: true}" label="Код подразделения" />
    <base-label label="Дата выдачи">
      <sweet-date-picker :class="{'invalid': errors.has('issue_date')}"  v-validate="'required'" name="issue_date" :range="false" type="date" v-model="model.issue_date" />
    </base-label>
    <base-input name="issued" v-model="model.issued" label="Кем выдан" />
  </div>
</template>

<script>

import ApiService from "../../services/api.service";
import SweetDatePicker from "@/components/form/SweetDatePicker.vue";
import BaseInput from "@/components/form/BaseInput.vue";
import {IMaskDirective} from "vue-imask";
import BaseLabel from "@/components/form/BaseLabel.vue";

export default {
  name: "PassportSettings",
  components: {BaseLabel, BaseInput, SweetDatePicker},
  directives: {
    imask: IMaskDirective
  },
  inject: ['$validator'],
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      },
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    ignorePayload: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      model: {},
      firstLoad: true
    }
  },
  async mounted() {
    if(!this.ignorePayload) {
      let res = await ApiService.get('userpassport');
      if(res.status === 200) {
        this.model = res.data ? res.data : {}
      }
    }
    this.firstLoad = false
  },
  watch: {
    model: {
      deep: true,
      handler: async function(val) {
        this.$emit('input', val)
      },
      immediate: true
    },
    'model.series': {
      handler: function (val) {
        if(val.length > 5) {
          this.model.series = val.slice(0, -1)
        }
      }
    },
    'model.number': {
      handler: function (val) {
        if(val.length > 7) {
          this.model.number = val.slice(0, -1)
        }
      }
    },
    'model.dep_code': {
      handler: function (val) {
        if(val.length > 7) {
          this.model.dep_code = val.slice(0, -1)
        }
      }
    },
  }
}
</script>

<style scoped>

</style>