<template>
  <div class="mt-5">
    <h3>Документы для загрузки</h3>
    <form @submit.prevent="validate()">
      <base-upload :sample="{link: '#', title: 'Образец соглашения'}"
                   title="Соглашение о взаимодействии" description="Документ, который ваша организация подписывает с органом исполнительной власти региона для участия в реализации мероприятий национального проекта «Производительность труда». Обращаем внимание, что соглашения, заключенные с ФЦК, РЦК, не подходят.Второй стороной в документе должен быть орган исполнительной власти региона."
                   v-model="acceleratorEnroll.contract"
                   :required="true" id="contract" name="contract"/>
      <base-upload :sample="{link: '#', title: 'Образец меморандума'}" :required="true" v-model="acceleratorEnroll.memorandum" id="memorandum" name="memorandum" title="Меморандум о сотрудничестве" description="Заполните меморандум на основании шаблона, подпишите у руководителя организации (например, генеральногой директора), проставьте оттиск печати и загрузите скан." />
      <base-upload :required="true" id="forward" name="forward" v-model="acceleratorEnroll.forward" title="Направление от компании" description="Заполните направление на основании шаблона на бланке организации с указанием ФИО и должности сотрудников, участвующих в акселерационной программе. Подпишите у руководителя организации (например, генеральный директор), проставьте оттиск печати и загрузите скан." />
      <base-upload :required="false" v-model="acceleratorEnroll.attorney" id="attorney" name="attorney" title="Доверенность на подписанта" description="Загружается в случае, если документы подписывает сотрудник организации, действующий по доверенности." />
      <div class="separator"></div>
      <accelerator-footer/>
    </form>
  </div>
</template>

<script>
import BaseUpload from "@/components/form/BaseUpload.vue";
import AcceleratorFooter from "@/components/profile/accelerator/AcceleratorFooter.vue";
import validateMixin from "@/mixins/validateMixin";
import {mapActions, mapMutations, mapState} from "vuex";
import {eventBus} from "@/main";

export default {
  name: "AcceleratorDocs",
  components: {AcceleratorFooter, BaseUpload},
  mixins: [validateMixin],
  mounted() {
    this.$on('on-submit', async () => {
      await this.updateAcceleratorEnroll({
        ...this.data,
        status: 'draft'
      })
      this.$toastr.s('', "Ченовик успешно сохранен");
    })
    eventBus.$on('on-send', async () => {
      await this.updateAcceleratorEnroll({
        ...this.data,
        status: 'send'
      })
      this.$toastr.s('', "Заявка успешно отправлена");
    })
  },
  methods: {
    ...mapActions(['updateAcceleratorEnroll']),
    ...mapMutations(['setAcceleratorEnroll'])
  },
  computed: {
    data() {
      return {
        forward: this.acceleratorEnroll.forward?.id,
        memorandum: this.acceleratorEnroll.memorandum?.id,
        contract: this.acceleratorEnroll.contract?.id,
        attorney: this.acceleratorEnroll.attorney?.id
      }
    },
    ...mapState({acceleratorFromStore: 'acceleratorEnroll'}),
    acceleratorEnroll: {
      get () {
        return this.acceleratorFromStore.acceleratorEnroll
      },
      set (value) {
        this.setAcceleratorEnroll(value)
      }
    }
  }
}
</script>

<style scoped>

</style>