import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import $ from "jquery";
import ProfileSettings from "@/views/profile/settings/ProfileSettings.vue";
import SubscribeSettings from "@/views/profile/settings/SubscribeSettings.vue";
import OnlineRequests from "@/views/profile/online/OnlineRequests.vue";
import OfflineRequests from "@/views/profile/offline/OfflineRequests.vue";
import OnlineDocs from "@/views/profile/online/OnlineDocs.vue";
import OfflineDocs from "@/views/profile/offline/OfflineDocs.vue";
import EventOrder from "@/views/EventOrder.vue";
import {TokenService} from "../../services/token.service";
import ProfileCompanySettings from "@/views/profile/settings/ProfileCompanySettings.vue";
import ReviewSeminar from "@/views/ReviewSeminar.vue";
import AcceleratorCompany from "@/views/profile/accelerator/AcceleratorCompany.vue";
import AcceleratorDocs from "@/views/profile/accelerator/AcceleratorDocs.vue";
import AcceleratorMember from "@/views/profile/accelerator/AcceleratorMember.vue";
import AcceleratorProfile from "@/views/profile/accelerator/AcceleratorProfile.vue";
import RegionTeam from "@/views/profile/regionteam/RegionTeam.vue";
import RegionTeamForm from "@/views/profile/regionteam/RegionTeamForm.vue";
import RegionTeamMembers from "@/views/profile/regionteam/RegionTeamMembers.vue";

Vue.use(VueRouter)

const user = TokenService.getUserObj()

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/calendar',
    alias: '/events',
    name: 'Calendar',
    component: () => import(/* webpackChunkName: "about" */ '../views/CalendarPage.vue')
  },
  {
    path: '/education',
    name: 'EducationPage',
    component: () => import(/* webpackChunkName: "about" */ '../views/EducationPage.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/howto',
    name: 'Howto',
    component: () => import(/* webpackChunkName: "about" */ '../views/HowTo.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import(/* webpackChunkName: "about" */ '../views/News.vue')
  },
  {
    path: '/news/:id',
    name: 'NewsPage',
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsPage.vue')
  },
  {
    path: '/news-archive',
    name: 'NewsArchive',
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsArchive.vue')
  },
  {
    path: '/education/:program/:course',
    name: 'Course',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Course.vue')
  },
  {
    path: '/course/:course/module/:module/lecture/:lecture',
    name: 'Video',
    meta: { auth: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Video.vue')
  },
  {
    path: '/partners',
    name: 'Partners',
    component: () => import('../views/Partners.vue')
  },
  {
    path: '/faq',
    name: 'Faq',
    component: () => import('../views/Faq.vue')
  },
  {
    path: '/acceleration',
    alias: '/acceleration/federal',
    name: 'Accelerator',
    component: () => import('../views/Accelerator.vue')
  },
  {
    path: '/tutors',
    name: 'Tutors',
    component: () => import('../views/Tutors.vue')
  },
  {
    path: '/teams',
    name: 'Teams',
    component: () => import('../views/Teams.vue')
  },
  {
    path: '/highschool',
    name: 'HighSchool',
    component: () => import('../views/HighSchool.vue')
  },
  {
    path: '/documents',
    name: 'Documents',
    component: () => import('../views/Documents.vue')
  },
  {
    path: '/sveden',
    name: 'Information',
    component: () => import('../views/info/Information.vue')
  },
  {
    path: '/sveden/common',
    name: 'InfoBasic',
    component: () => import('../views/info/InfoBasic.vue')
  },
  {
    path: '/sveden/struct',
    name: 'InfoStructure',
    component: () => import('../views/info/InfoStructure.vue')
  },
  {
    path: '/sveden/document',
    name: 'InfoDocs',
    component: () => import('../views/info/InfoDocs.vue')
  },
  {
    path: '/sveden/education',
    name: 'InfoEdu',
    component: () => import('../views/info/InfoEdu.vue')
  },
  {
    path: '/sveden/employees',
    name: 'InfoLeads',
    component: () => import('../views/info/InfoLeads.vue')
  },
  {
    path: '/sveden/objects',
    name: 'InfoMaterial',
    component: () => import('../views/info/InfoMaterial.vue')
  },
  {
    path: '/sveden/grants',
    name: 'InfoScholarship',
    component: () => import('../views/info/InfoScholarship.vue')
  },
  {
    path: '/sveden/paid_edu',
    name: 'PaidEdu',
    component: () => import('../views/info/InfoServices.vue')
  },
  {
    path: '/sveden/budget',
    name: 'InfoBudget',
    component: () => import('../views/info/InfoBudget.vue')
  },
  {
    path: '/sveden/anti-corruption',
    name: 'InfoAntiCorruption',
    component: () => import('../views/info/InfoAntiCorruption.vue')
  },
  {
    path: '/sveden/zakupki',
    name: 'InfoZakupki',
    component: () => import('../views/info/InfoZakupki.vue')
  },
  {
    path: '/sveden/accreditations',
    name: 'InfoAccreditations',
    component: () => import('../views/info/InfoAccreditations.vue')
  },
  {
    path: '/sveden/vacant',
    name: 'Vacant',
    component: () => import('../views/info/InfoVacancy.vue')
  },
  {
    path: '/sveden/ovz',
    name: 'Ovz',
    component: () => import('../views/info/InfoAccessible.vue')
  },
  {
    path: '/sveden/inter',
    name: 'InfoInt',
    component: () => import('../views/info/InfoInt.vue')
  },
  {
    path: '/sveden/inter',
    name: 'Inter',
    component: () => import('../views/info/InfoInt.vue')
  },
  {
    path: '/sitemap',
    name: 'Sitemap',
    component: () => import('../views/Sitemap.vue')
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    component: () => import('../views/SignInUp.vue')
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: () => import('../views/SignInUp.vue')
  },
  {
    path: '/mentoring',
    name: 'Mentoring',
    component: () => import('../views/Mentoring.vue')
  },
  {
    path: '/online',
    name: 'OnlineOrder',
    component: () => import('../views/OnlineOrder.vue')
  },
  {
    path: '/lk',
    name: 'Profile',
    meta: {auth: true},
    component: () => import('../views/profile/Profile.vue'),
    children: [
      { path: '', alias:'settings', name: 'ProfileSettings', component: ProfileSettings },
      { path: 'company', name: 'ProfileCompanySettings', component: ProfileCompanySettings },
      { path: 'subscribes', name: 'SubscribeSettings', component: SubscribeSettings },
      {
        path: 'docs',
        name: 'Docs',
        component: () => import('../views/profile/libs/Docs.vue'),
        children: [
          { path: '', name: 'OnlineDocs', component: OnlineDocs, alias: 'online' },
          { path: 'offline', name: 'OfflineDocs', component: OfflineDocs },
        ]
      },
      {
        path: 'requests',
        name: 'Requests',
        component: () => import('../views/profile/Requests.vue'),
        children: [
          { path: '', name: 'OnlineRequests', component: OnlineRequests, alias: 'online' },
          { path: 'offline', name: 'OfflineRequests', component: OfflineRequests },
        ]
      },
      {
        path: 'region-team',
        name: 'RegionTeam',
        component: () => import('../views/profile/regionteam/RegionTeam.vue'),
        children: [
          { path: '', name: 'RegionTeamForm', component: RegionTeamForm, alias: 'form' },
          { path: 'members', name: 'RegionTeamMembers', component: RegionTeamMembers }
        ]
      },
      {
        path: 'ebs',
        name: 'Ebs',
        component: () => import('../views/profile/libs/Ebs.vue')
      },
      {
        path: 'seminars',
        name: 'Seminars',
        component: () => import('../views/profile/offline/Seminars.vue'),
      },
      {
        path: 'seminars/:id',
        name: 'SeminarsId',
        component: () => import('../views/profile/offline/Seminars.vue'),
        children: [
          {
            path: 'stats',
            name: 'SeminarStat',
            component: () => import('../views/profile/offline/SeminarStat.vue'),
          }
        ]
      },
      {
        path: 'seminars/:id/invite',
        name: 'SeminarInvite',
        component: () => import('../views/profile/offline/SeminarInvite.vue'),
      },
      {
        path: 'seminar-order/:id',
        name: 'Seminars',
        component: () => import('../views/profile/offline/SeminarOrder.vue'),
      },
      {
        path: 'online',
        name: 'Online',
        component: () => import('../views/profile/online/Online.vue'),
      },
      {
        path: 'test/:id',
        name: 'Test',
        component: () => import('../views/profile/Test.vue'),
      },
      {
        path: 'tutor-library',
        name: 'TutorLibs',
        component: () => import('../views/profile/libs/TutorLibs.vue')
      },
      {
        path: 'notifications',
        name: 'Notification',
        component: () => import('../views/profile/Notification.vue'),
      },
      {
        path: 'recommend',
        name: 'Recommend',
        component: () => import('../views/profile/Recommend.vue'),
      },
      {
        path: 'accelerator',
        name: 'Accelerator',
        component: () => import('../views/profile/accelerator/Accelerator.vue'),
        children: [
          { path: '', alias:'company', name: 'AcceleratorCompany', component: AcceleratorCompany },
          { path: 'docs', name: 'AcceleratorDocs', component: AcceleratorDocs },
          { path: 'members', name: 'AcceleratorMember', component: AcceleratorMember },
          { path: 'profile', name: 'AcceleratorProfile', component: AcceleratorProfile },
        ]
      },
    ],
  },
  {
    path: '/events/:id',
    name: 'Events',
    component: () => import('../views/Event.vue'),
    children: [
      { path: '', name: 'EventOrder', component: EventOrder },
      { path: 'review', name: 'OfflineRequests', component: ReviewSeminar },
    ]
  },
  {
    path: '/fin-for-girls',
    alias: '/fin_for_girls',
    name: 'FinForGirls',
    component: () => import('../views/FinForGirls.vue')
  },
  { path: "*",
    component: () => import('../components/PageNotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  linkExactActiveClass: "active",
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if(to.name !== 'Home') {
      $('html, body').css({
        overflow: 'auto',
        height: 'auto'
      });
      $('#menu').remove();
      $('.open').removeClass('open')
    }

    if(from.name === 'SignInUp') {
      const element = document.getElementsByTagName("html")[0];
      element.classList.remove("fp-viewing-footer");
      element.classList.remove("authorization-bg");
    }

    if(from.name === 'Home' && !to.hash) {
      const element = document.getElementsByTagName("body")[0];
      element.classList.remove("fp-viewing-footer");
    }

    const element = document.getElementsByTagName("body")[0];
    element.classList.remove("show-mainmenu");

    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      };
    }

    return { x: 0, y: 0, behavior: 'smooth' }
  }
})

const COMPANY_ROLES = ['ROLE_COMPANY', 'ROLE_COMPANY_CRM', 'ROLE_REGION_TEAM'];

function getLkPath(hasRole: string) {
  if(hasRole === 'ROLE_SUPER_ADMIN') {
    return '/profile/settings/personal';
  }
  if(hasRole === 'ROLE_COMPANY') {
    return '/lk/accelerator';
  }
  if(hasRole === 'ROLE_REGION_TEAM') {
    return '/lk/region-team';
  }
  if(hasRole === 'ROLE_COMPANY_CRM') {
    return '/lk/company';
  }
}

router.beforeEach((to, from, next) => {
  if(typeof to.name === "undefined" && to.path.indexOf('/profile') > -1) {
    window.location.href = to.path
    return;
  }

  if(to.name === 'ProfileSettings' && user) {
    let hasRole = null
    user.roles.forEach((role: string) => {
      if(COMPANY_ROLES.indexOf(role) > -1) {
        hasRole = role
      }
    })
    if(hasRole) {
      next({path: getLkPath(hasRole)})
    }
  }

  if (to.matched.some(m => m.meta.auth) && !TokenService.getUser()) {
    TokenService.clear()
    next({ name: 'SignIn' })
  } else {
    next()
  }
})

export default router
