<template>
  <div class="modal fade" id="seminar-download-doc" tabindex="-1" role="dialog" aria-labelledby="add-question" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <form>
        <div class="modal-content">
          <div class="modal-header modal-header-danger-bg">
            <div class="title-with-icon">
              <div class="title-with-icon__title">
                {{ title }}
              </div>
            </div>
            <button id="modal-close" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="pt-3">{{ text }}</div>
          </div>
          <div class="modal-footer d-flex justify-content-end">
            <button id="modal-close" type="button" class="btn btn-primary" data-dismiss="modal" aria-label="Close">ОК</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

window.jQuery = $
export default {
  name: "ModalSeminarDownload",
  props: {
    title: {
      type: String,
      default() {
        return 'Шаблон направления на обучение'
      }
    },
    text: {
      type: String,
      default() {
        return 'Пожалуйста, заполните все обязательные поля в заявке, чтобы мы сформировали для Вас шаблон направления на обучение.'
      }
    }
  }
}
</script>

<style scoped>

</style>