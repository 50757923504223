import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ApiService from "../services/api.service";


// @ts-ignore
import VueYandexMetrika from 'vue-yandex-metrika';
import moment from "moment";
// @ts-ignore
import numeral from "numeral";
export const eventBus = new Vue()

// @ts-ignore
import VueCarousel from "vue-carousel";
Vue.use(VueCarousel);
import store from "./store";

// @ts-ignore
import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

// @ts-ignore
import VueToastr from 'vue-toastr';
Vue.use(VueToastr, {
  defaultTimeout: 6000,
  defaultProgressBar: false,
  defaultProgressBarValue: 0,
  defaultType: "error",
  defaultPosition: "toast-top-right",
  defaultCloseOnHover: false,
  defaultClassNames: ["animated", "zoomInUp"]
});

import VeeValidate from 'vee-validate'
Vue.use(VeeValidate)

// @ts-ignore
import VueAutosuggest from 'vue-autosuggest';
Vue.use(VueAutosuggest);

import VueSocialSharing from 'vue-social-sharing'
import {TokenService} from "../services/token.service";
// @ts-ignore
import _ from "lodash";
Vue.use(VueSocialSharing);

// @ts-ignore
import wysiwyg from 'vue-wysiwyg';
Vue.use(wysiwyg, {}); // config is optional. more below

Vue.config.productionTip = false

ApiService.init(process.env.VUE_APP_ROOT_API);

Vue.use(VueYandexMetrika, {
  id: 37249290,
  router: router,
  env: process.env.NODE_ENV
})

Vue.prototype.$hasRole = (role: string) => {
  return TokenService.hasRole(role)
}
Vue.prototype.$hasRoles = (roles: []) => {
  return TokenService.hasRoles(roles)
}
Vue.prototype.$isOLdLk = () => {
  //@ts-ignore
  return TokenService.hasRoles([
    'ROLE_SUPER_ADMIN',
    'ROLE_COMPANY',
    'ROLE_COMPANY_CRM',
    'ROLE_SELECT_TUTOR_EF',
    'ROLE_SELECT_MENTOR_EF',
    'ROLE_SELECT_MENTOR_AER',
    'ROLE_SELECT_TUTOR_AER',
    'ROLE_SELECT_TUTOR_MSP',
    'ROLE_SELECT_TUTOR_SEMINAR',
    'ROLE_CRM_MENTORING_CLIENT',
    'ROLE_CRM_MENTORING_CLIENT_REQUEST',
    'ROLE_TUTOR',
  ])
}

new Vue({
  router,
  render: h => h(App),
  store
}).$mount('#app')

Vue.filter('formatDate', (value: any, format: 'DD.MM.YYYY HH:mm') => {

  moment.locale('ru')
  const number = moment(String(value)).format('M');
  let monthName = null
  switch (number) {
    case '1':
      monthName = 'января'
      break;
    case '2':
      monthName = 'февраля'
      break;
    case '3':
      monthName = 'марта'
      break;
    case '4':
      monthName = 'апреля'
      break;
    case '5':
      monthName = 'мая'
      break;
    case '6':
      monthName = 'июня'
      break;
    case '7':
      monthName =  'июля'
      break;
    case '8':
      monthName = 'августа'
      break;
    case '9':
      monthName = 'сентября'
      break;
    case '10':
      monthName = 'октября'
      break;
    case '11':
      monthName = 'ноября'
      break;
    case '12':
      monthName = 'декабря'
      break;
  }

  // @ts-ignore
  if('M' === format) {
    return monthName;
  }

  // @ts-ignore
  if('full' === format) {
    return moment(String(value)).utc().format('DD') + ' ' + monthName + ' ' + moment(String(value)).utc().format('YYYY')
        + ' в ' +  moment(String(value)).utc().format('HH:mm')
  }

  // @ts-ignore
  if('full_date_only' === format) {
    return moment(String(value)).utc().format('DD') + ' ' + monthName + ' ' + moment(String(value)).utc().format('YYYY')
  }

  if (value) {
    return moment(String(value)).utc().format(format)
  }
})
Vue.filter('truncate', function(text:string, length:number, clamp: '...'){
  const node = document.createElement('div');
  node.innerHTML = text;
  const content = node.textContent;
  // @ts-ignore
  return content.length > length ? content.slice(0, length) + clamp : content;
});
Vue.prototype.camelize = (obj: any) => {
  const res = {}
  Object.keys(obj).forEach(field => {
    // @ts-ignore
    res[_.camelCase(field)] = obj[field]
  })
  return res;
}

//console.log(plural(['яблоко', 'яблока', 'яблок'], 0));
// 1 яблоко, 2 яблока, 5 яблок
// @see http://docs.translatehouse.org/projects/localization-guide/en/latest/l10n/pluralforms.html
Vue.filter('pluralize', function(n: number, locale: string, forms: any[], withNumber: boolean = false){
  let idx;
  if (n % 10 === 1 && n % 100 !== 11) {
    idx = 0; // one
  } else if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) {
    idx = 1; // few
  } else {
    idx = 2; // many
  }
  const word = forms[idx] || ''
  return withNumber ? n + ' ' + word : word;
})

Vue.filter('pluralize', function(number: number, locale: string, words: string[]){

  if(!words.length) {
    return number;
  }

  if (number % 10 === 1 && number % 100 !== 11) {
    return words[0];
  }
  if ((number % 10 >= 2 && number % 10 <= 4 && number % 100 < 10) || number % 100 >= 20) {
    return words[1];
  }

  return words[2];
})

Vue.filter("formatNumber", function (value: any, format = "0,0") {
  numeral.localeData().delimiters.thousands = ' ';
  return numeral(value).format(format);
});

