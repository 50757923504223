<template>
  <div class="form-item" v-if="error">
    <div class="notice active notice_red restore-notice">
      <div class="content_subtitle content_red content_700 mb-1">{{ msg.title }}</div>
      <div class="content_18">{{ msg.text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormError",
  props: {
    error: {
      type: [Error, Object],
      default: null
    }
  },
  data() {
    return {
      msg: {
        title: 'Ошибка!',
        text: 'Пожалуйста, проверьте правильно ли заполнены поля формы.'
      }
    }
  },
  mounted() {
    console.log(this.error)
    if (this.error.response) {
      if(this.error.response.data.error) {
        this.msg.title = this.error.response.data.error;
      }
      if(this.error.response.data.error_description) {
        this.msg.text = this.error.response.data.error_description;
      }
      if(this.error.response.data.errors && this.error.response.data.errors.errors) {
        this.msg.text = this.error.response.data.errors.errors
      }
    } else if (this.error.data) {
      if(this.error.data.error) {
        this.msg.title = this.error.data.error;
      }
      if(this.error.data.error_description) {
        this.msg.text = this.error.data.error_description;
      }
    } else {
      this.msg.text = this.error.message
    }
    this.translate()
  },
  methods: {
    translate() {
      if(this.msg.title === 'invalid_grant') {
        this.msg.title = 'Неправильный логин или пароль'
      }
      if(this.msg.text === 'Invalid username and password combination') {
        this.msg.text = 'Возможно, у Вас выбрана другая раскладка клавиатуры или нажата клавиша Caps Lock.'
      }
    }
  }
}
</script>

<style scoped>

</style>