<template>
  <div class="modal fade" :id="id" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" :class="modalClass">
      <div class="modal-content">
        <div class="modal-header" :class="headerClass">
          <div class="title-with-icon">
            <div v-if="icon" class="title-with-icon__icon title-with-icon__icon-white" :class="'title-with-icon__icon_' + icon"></div>
            <div class="title-with-icon__title">{{ title }}</div>
          </div>
          <button id="modal-close" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
window.jQuery = $
export default {
  name: "BaseModal",
  props: {
    icon: {
      type: [String, Boolean],
      default() {
        return 'exclamation'
      }
    },
    title: {
      type: String,
      required: true
    },
    modalClass: {
      type: String,
      default() {
        return 'modal-xl'
      }
    },
    headerClass: {
      type: String,
      default() {
        return 'modal-header-blue-bg'
      }
    },
    value: {
      type: Boolean,
      required: true
    },
    id: {
      type: String,
      default() {
        return 'modal'
      }
    }
  },
  mounted() {
    let self = this
    $('#' + this.id).on('hide.bs.modal', function () {
      self.$emit('input', false)
    })
  },
  watch: {
    value: {
      handler: async function(val) {
        $('#' + this.id).modal(val ? 'show' : 'hide');
      }
    }
  }
}
</script>

<style scoped>

</style>