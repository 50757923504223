import axios, {AxiosRequestConfig} from "axios";
import qs from "qs";
import {TokenService} from "./token.service";
const ApiService = {
	_requestInterceptor: 0,
	_401interceptor: 0,

	init(baseURL: string | undefined) {
		axios.defaults.baseURL = baseURL;
		axios.interceptors.response.use(
			(res) => {
				return res;
			},
			async (err) => {
				const originalConfig = err.config;
				if (err.response) {
					// Access Token was expired
					if (err.response.status === 401 && !originalConfig._retry) {
						originalConfig._retry = true;
						try {
							TokenService.clear()
						} catch (_error) {
							if (_error.response && _error.response.data) {
								return Promise.reject(_error.response.data);
							}
							return Promise.reject(_error);
						}
					}
					if (err.response.status === 403 && err.response.data) {
						return Promise.reject(err.response.data);
					}
				}
				return Promise.reject(err);
			}
		);
	},

	baseURL() {
		return axios.defaults.baseURL?.replace('api/', '')
	},

	async register(register: any = {}) {
		return await axios.post(this.baseURL() + 'registration', register)
	},

	async restore(email: string) {
		return await axios.get(this.baseURL() + 'reset/request', {
			params: {
				email: email
			}
		})
	},

	async login(username: string, password: string, remember: boolean = false, redirect:string = '') {
		const response = await axios.get(this.baseURL() + 'oauth/v2/token', {
			params: {
				client_id:  '10_2q3gla9h61es8kg80gokwskccksk0g44g8wg4gws4wgo84oc0w',
				grant_type: 'password',
				password:   password,
				username:   username
			}
		})

		const tokenData = response.data;

		await TokenService.setToken(tokenData.access_token)
		await TokenService.setExpire(tokenData.expires_in)

		if(remember) {
			await TokenService.set('refresh_token', tokenData.refresh_token)
		}

		if(redirect) {
			if(redirect === 'crm') {
				window.location.href = process.env.VUE_APP_CRM_AUTH + '?access_token=' + tokenData.access_token
			}
			if(redirect === 'crm-m') {
				window.location.href = process.env.VUE_APP_CRM_M_AUTH + '?access_token=' + tokenData.access_token
			}
		}

		await this.setHeader()

		return response;
	},

	async getMe() {
		const resp = await axios.get('user')
		const data = resp.data
		if(resp.status === 200) {
			TokenService.setUser(data)
		}
		return resp
	},
	async getMeOrganization() {
		const resp = await axios.get('userorganization')
		const data = resp.data
		if(resp.status === 200) {
			TokenService.setOrganization(data)
		} else {
			throw new Error(resp.data)
		}
		return resp
	},
	async getMePassport() {
		const resp = await axios.get('userpassport')
		const data = resp.data
		if(resp.status === 200) {
			TokenService.setPassport(data)
		}
		return resp
	},
	async getData() {
		await this.getMe()
		await this.getMePassport()
	},
	setHeader() {
		if(TokenService.getToken()) {
			const token = TokenService.getToken()?.replaceAll('"', '');
			if(typeof token !== undefined) {
				axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
			} else {
				this.removeHeader()
			}
		}
	},

	removeHeader() {
		axios.defaults.headers.common["Authorization"] = null;
	},

	get(resource: string, params= {}) {
		return axios.get(resource, params);
	},

	post(resource: string, data: any, withCredentials: true, headers: any = {"Content-Type": "application/x-www-form-urlencoded"}) {
		return axios.post(resource, qs.stringify(data), {
			headers: headers,
			withCredentials: withCredentials
		});
	},

	postJson(resource: string, data: any) {
		return axios.post(resource, data);
	},

	postFormData(resource: string, data: FormData) {
		return axios.post(resource, data, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
	},

	put(resource: string, data: any) {
		return axios.put(resource, data);
	},

	delete(resource: string) {
		return axios.delete(resource);
	},
}

import {eventBus} from "@/main";

axios.interceptors.request.use((config) => {
	const hideLoader = config.params && config.params.hideLoader
	if(!hideLoader) {
		eventBus.$emit('toggle-loader', true);
	}
	if(config.url === 'token/refresh') {
		config.data = {...config.data, refresh_token: localStorage.getItem('refresh_token')}
	}

	if(config.params && config.params.hideLoader) {
		return config;
	}
	return config;
});

let hasAccessDenied = false
let hasErrorFired = false
let hasAccessUnauthorized = false

const errorHandler = (error: any) => {

	if(!error) {
		eventBus.$emit('toggle-loader', false);
		return;
	}

	if(error.response.status === 401) {
		TokenService.clearUser()
		TokenService.removeToken()
		localStorage.setItem('access:path', window.location.pathname)
		if(!hasAccessUnauthorized) {
			eventBus.$emit('access:unauthorized');
			hasAccessUnauthorized = true
		}
	} else if(error.response.status === 403) {
		if(!hasAccessDenied) {
			hasAccessDenied = true
			eventBus.$emit('access:denied');
		}
	} else if(error.response.status > 299) {
		if(!hasErrorFired) {
			eventBus.$emit('error:fired', error);
			hasErrorFired = true
		}
	}

	// Ставим таймер и не спамим в течение 2 секунд лишними ошибками
	setTimeout(function () {
		hasAccessDenied = false
		hasErrorFired = false
		hasAccessUnauthorized = false
	}, 2000)

	eventBus.$emit('toggle-loader', false);
	return error.response

}

const successHandler = (response: any) => {
	eventBus.$emit('toggle-loader', false);
	return response
}

axios.interceptors.response.use(
	response => successHandler(response),
	error => errorHandler(error)
)

export default ApiService;
