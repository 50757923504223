// @ts-nocheck
export default {
    computed: {
        lkPath() {
            if(this.$hasRole('ROLE_SUPER_ADMIN')) {
                return '/profile/settings/personal';
            }else if(this.$hasRole('ROLE_COMPANY')) {
                return '/lk/accelerator';
            } else if(this.$hasRole('ROLE_REGION_TEAM')) {
                return '/lk/region-team';
            } else {
                return '/lk';
            }
        }
    }
}