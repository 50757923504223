<template>
  <div class="wrapper" id="app" :class="{loading: loader}">
    <spinner v-if="loader" />
    <sidebar/>
    <main class="main">
      <head-menu/>
      <router-view />
    </main>
    <main-menu/>
    <search/>
    <modal/>
    <modal-recall-me/>
  </div>
</template>

<script>

import Sidebar from "@/components/Sidebar";
import MainMenu from "@/components/MainMenu";
import Search from "@/components/Search";
import Modal from "@/components/Modal";
import HeadMenu from "@/components/HeadMenu";
import ApiService from "../services/api.service";
import ModalRecallMe from "@/components/ModalRecallMe";
import Spinner from 'vue-simple-spinner'
import {eventBus} from "@/main";
import {TokenService} from "../services/token.service";
import {mapActions} from "vuex";
export default {
  name: 'App',
  components: {ModalRecallMe, HeadMenu, Modal, Search, MainMenu, Sidebar, Spinner},
  data() {
    return {
      loader: false
    }
  },
 async mounted() {
    eventBus.$on('toggle-loader', (loader) => {
      this.loader = loader;
    });

   eventBus.$on('access:denied', () => {
     this.$toastr.e('', 'Ошибка доступа')
     this.$router.push('/lk').catch(() => {})
   });

    eventBus.$on('access:unauthorized', () => {
      this.$toastr.e('', 'Необходима авторизация')
      this.$router.push('/sign-in').catch(() => {})
    });

   eventBus.$on('error:fired', (error) => {
     console.error(error)
   });

    if(TokenService.getUser()) {
      await ApiService.setHeader();
      await ApiService.getData()
      await this.getOrganization()
      await this.getNotes()
    }

    const params = new URLSearchParams(window.location.search);

    if(params.get('access_token')) {
      await TokenService.setToken(params.get('access_token'))
      await ApiService.setHeader()
      await ApiService.getData()
      await this.getOrganization()
      await this.getNotes()
      window.location.href = '/lk'
    }
  },
  methods: {
    ...mapActions(['getNotes', 'getOrganization']),
    checkExist(script) {
      let exist = false
      document.querySelectorAll('script').forEach(tag => {
        if(tag.getAttribute('src') === script) {
          exist = true
        }
      })
      return exist;
    },
    init() {
      let tag = document.createElement("script");
      tag.setAttribute("src",'/js/jquery.min.js');
      document.head.appendChild(tag);
      let scripts = [
        '/js/scrolloverflow.js',
        '/js/owl.carousel.js',
        '/js/chosen.jquery.min.js',
        '/js/jquery.mask.min.js',
        '/js/jquery.fullPage.js'
      ];

      scripts.forEach((script, key) => {
        setTimeout(() => {
            if(!this.checkExist(script)) {
              let tag = document.createElement("script");
              tag.setAttribute("src", script);
              document.head.appendChild(tag);
              if(key === 4) {
                tag.onload = () => {
                  let mainTag = document.createElement("script");
                  mainTag.setAttribute("src", '/js/my.js');
                  document.head.appendChild(mainTag);
                  mainTag.onload = () => {
                    let lottie = document.createElement("script");
                    lottie.setAttribute("src", 'https://cdnjs.cloudflare.com/ajax/libs/bodymovin/5.7.4/lottie.min.js');
                    document.head.appendChild(lottie);
                    lottie.onload = () => {
                      let anim = document.createElement("script");
                      anim.setAttribute("src", '/js/arrow.js');
                      document.head.appendChild(anim);
                    }
                    let util = document.createElement("script");
                    util.setAttribute("src", '/js/util.js');
                    document.head.appendChild(util);
                    let modal = document.createElement("script");
                    modal.setAttribute("src", '/js/modal.js');
                    document.head.appendChild(modal);
                    let anim = document.createElement("script");
                    anim.setAttribute("src", '/js/collapse.js');
                    document.head.appendChild(anim);
                    util.onload = () => {
                      const tabs = document.createElement("script");
                      tabs.setAttribute("src",'/js/tabs.js');
                      document.head.appendChild(tabs);
                      tabs.onload = () => {
                        const tabHref = document.createElement("script");
                        tabHref.setAttribute("src", '/js/tabhref.js');
                        document.head.appendChild(tabHref);
                      }
                    }
                  }
                }
              }
            }
          }, 200)
      });
    }
  },
  watch: {
    '$route': function (route) {
      let utm = route.query.utm_source
      if(utm) {
        ApiService.post('sources', {
          name: 'https://' + window.location.host + route.path,
          action: 'link',
          description: utm
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import "~vue-wysiwyg/dist/vueWysiwyg.css";
@import "assets/style/main";
.avatar.avatar_big {
  background-size: cover;
}
.section__company .title-with-icon_menu .title-with-icon__title a {
  margin-left: -5rem;
}
.btn-ecp {
  margin-left: 20px;
}
.blue-text {
  color: $primary;
}
.red-text {
  color: $secondary;
}
blockquote {
  margin-bottom: 5rem!important;
  padding: 2.4rem 4.8rem;
  background: #fff;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.3;
  border-left: .8rem solid #dc3545;
}
.vue-simple-spinner {
  position: fixed;
  left: calc(50% - 17px);
  top: calc(50% - 17px);
  z-index: 10000;
  border-color: #002E5E rgb(238, 238, 238) rgb(238, 238, 238)!important;
}
.loading {
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    z-index: 10000;
    cursor: wait;
    position: absolute;
  }
}
</style>
