<template>
  <select class="form-control" :id="id" :name="name" data-placeholder="Выбрать из списка">
    <option value="" :selected="value == ''">Выбрать из списка</option>
    <option v-for="(option,index) in options" :key="index" :value="option[itemValue]"  :selected="option[itemValue] == value">
      {{ option[itemTitle] }}
    </option>
  </select>
</template>

<script>
import $ from 'jquery'
import 'chosen-js'
export default {
  name: "SweetSelect",
  props: {
    options: {
      type: Array,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    itemValue: {
      type: String,
      default: 'id'
    },
    itemTitle: {
      type: String,
      default: 'name'
    },
    value: {
      type: String
    },
    name: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selected: this.value
    }
  },
  mounted() {

    let id = '#' + this.id;

    /*$(id).on('chosen:showing_dropdown', () => {
      let options = $(id + '_chosen').find('li')
      options.each(function () {
        $(this).html('<b>Экспортные семинары</b>' + '&nbsp;' + $(this).html())
      })
    })*/

    let self = this
    $(id).chosen({
      disable_search_threshold: 10,
      allow_single_deselect: true
    }).change(function(event){
      if(event.target === this) {
        self.$emit('input', $(this).val())
      }
    })
  }
}
</script>

<style scoped>
.select-header {

}
</style>